import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import heroStyles from '../components/hero.module.css'
import Img from 'gatsby-image'
import Button from '../components/button'

class AboutIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [headerImage] = get(this, 'props.data.allContentfulAsset.edges')

    return (
      <Layout location={this.props.location} pageName={'about'} >
        <div style={{ background: '#fff' }}>
          <Helmet title={'About | ' + siteTitle} defer={false}/>
          <div className={heroStyles.hero}>
            <Img className={heroStyles.heroImage} alt={headerImage.node.title} fluid={headerImage.node.fluid} />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">Who is Coffee In Code Out?</h1>
            <p>
              Coffee In Code Out is a small web development shop run by me, Warner Onstine. I am an industry veteran of over 20 years. I work with companies of all sizes, from startups to large corporations.
            </p>
            <p>
              I started off my career doing technical support, listening to how customers actually use their software. This experience has stuck with me and is something I try and bring to bear on every project I work on. How will the user actually use this piece of software? What do they need to get done? What problem are they trying to solve?
            </p>
            <p>I've also had a variety of positions and roles throughout my career:</p>
            <ul>
              <li>Lead Developer</li>
              <li>Manager</li>
              <li>Director</li>
              <li>Project Manager</li>
              <li>Product Manager</li>
              <li>DevOps</li>
              <li>Coach/Mentor</li>
              <li>Trainer</li>
              <li>Author</li>
            </ul>
            <p>
              Over the years, I've used many different languages, starting with ColdFusion (2+ years), then PHP (6+ years), Java (15+ years), Perl (2+ years), Ruby (2+ years), and now JS/Node.js (8+ years). My specialty right now is designing and building Node.js APIs, specifically microservices.
            </p>
            <p>
              I can't count how many different web applications I've built from the ground up, added new features to or done a rewrite on, but here are some that I'm the most proud of:
            </p>
            <ul>
              <li>City Recess - an event discovery platform to help users find out about events before they miss them.</li>
              <li>Primeloop - an ad tech platform to help clients engage with their potential customers better</li>
              <li>API Microservice - a suite of microservices designed to connect to multiple back-end sources taking disparate data and making it homogeneous through an API</li>
            </ul>
            <p>
              I love building things, and I love helping teams learn new things and become better. Let's chat and see how I can help you.
            </p>
            <a href={"https://calendly.com/warnero/intro"} className={"btn btn-blue"}>Let's Get Started!</a>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutIndex

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAsset(filter: {contentful_id: {eq: "4kGAsWXtQ8Fmvo2XvBPAJ8"}}) {
      edges {
        node {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
